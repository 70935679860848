import Modal from "@/Components/Modal";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { toggleSortModal } from "@/redux/slices/appSettingSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link, usePage } from "@inertiajs/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function () {
    const {
        locale: { dir, lang },
        appSetting: {
            sortModal: { showSortModal, element },
        },
    } = useAppSelector((state) => state);
    const {
        ziggy: { query },
    }: any = usePage().props;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [message, setMessage] = useState<string>("");

    const handleDelete = () => {
        // router.delete(
        //     route(`${lang}.backend.${element.name}.destroy`, {
        //         id: element?.id,
        //     }),
        //     {
        //         headers: {
        //             Authorization: `Bearer ${api_token}`,
        //         },
        //         preserveScroll: true,
        //         onFinish: () => closeModal(),
        //     },
        // );
    };

    const closeModal = () => dispatch(toggleSortModal(null));

    return (
        <Modal show={showSortModal} onClose={() => closeModal()} maxWidth="lg">
            <div
                dir={dir}
                className="flex flex-col w-full justify-start items-center gap-y-4 py-4  rounded-2xl "
            >
                <div className="flex flex-row justify-center items-center">
                    <XMarkIcon
                        onClick={() => closeModal()}
                        className="absolute left-6  top-8 mx-3 w-6 h-6 text-gray-600"
                    />
                </div>
                <div className="grid grid-rows-4 w-full divide-y divide-gray-300  pt-8">
                    {element === "course" && (
                        <>
                            <Link
                                as="button"
                                preserveScroll
                                onClick={() => closeModal()}
                                className="flex justify-start items-center p-4 px-6 hover:bg-gray-100"
                                href={route(
                                    `${lang}.frontend.${element}.index`,
                                    {
                                        ...query,
                                        sort: "start_date_desc",
                                    },
                                )}
                            >
                                {t("sort_by_start_date_desc")}
                            </Link>
                            <Link
                                as="button"
                                preserveScroll
                                onClick={() => closeModal()}
                                className="flex justify-start items-center p-4 px-6 hover:bg-gray-100"
                                href={route(
                                    `${lang}.frontend.${element}.index`,
                                    {
                                        ...query,
                                        sort: "start_date_asc",
                                    },
                                )}
                            >
                                {t("sort_by_start_date_asc")}
                            </Link>
                        </>
                    )}
                    <Link
                        as="button"
                        preserveScroll
                        onClick={() => closeModal()}
                        className="flex justify-start items-center p-4 px-6 hover:bg-gray-100"
                        href={route(`${lang}.frontend.${element}.index`, {
                            ...query,
                            sort: "name_desc",
                        })}
                    >
                        {t("sort_by_name_desc")}
                    </Link>
                    <Link
                        as="button"
                        preserveScroll
                        onClick={() => closeModal()}
                        className="flex justify-start items-center p-4 px-6 hover:bg-gray-100"
                        href={route(`${lang}.frontend.${element}.index`, {
                            ...query,
                            sort: "name_asc",
                        })}
                    >
                        {t("sort_by_name_asc")}
                    </Link>
                </div>
            </div>
        </Modal>
    );
}
