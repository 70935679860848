import SortModal from '@/Components/SortModal';
import MainFooter from '@/Pages/Frontend/Partials/MainFooter';
import MainHead from '@/Pages/Frontend/Partials/MainHead';
import NavHeader from '@/Pages/Frontend/Partials/NavHeader';
import {whatsappUrl} from '@/constants';
import {useAppDispatch, useAppSelector} from '@/redux/hooks';
import {setLocale} from '@/redux/slices/localeSlice';
import {
    showErrorToastMessage,
    showSuccessToastMessage,
} from '@/redux/slices/toastMessageSlice';
import {TooltipContent} from '@/shadcn/ui/tooltip';
import {AppProps} from '@/types';
import {usePage} from '@inertiajs/react';
import {Tooltip, TooltipTrigger} from '@radix-ui/react-tooltip';
import {first, isEmpty, isNull, values} from 'lodash';
import {ReactNode, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {FaWhatsapp} from 'react-icons/fa';

export default function ({
    title,
    children,
}: {
    title?: string;
    children: ReactNode;
}): React.ReactNode {
    const {t} = useTranslation();
    const {
        locale: {lang},
        appSetting: {sortModal, popupModal, videoModal},
        toastMessage: {showToast, content, type},
    } = useAppSelector((state) => state);
    const {
        props: {flash, currentLang, setting, errors},
    }: AppProps = usePage();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (lang !== currentLang) {
            dispatch(setLocale(currentLang));
        }
    }, [currentLang, lang]);

    useEffect(() => {
        if (!showToast) {
            if (flash && !isNull(flash.success)) {
                dispatch(showSuccessToastMessage({content: flash.success}));
            } else if (flash && !isNull(flash.error)) {
                dispatch(showErrorToastMessage({content: flash.error}));
            } else if (!isEmpty(errors)) {
                dispatch(
                    showErrorToastMessage({
                        content: first(values(errors)),
                    }),
                );
            }
        }
    }, [currentLang, flash, errors]);

    return (
        <div
            className="max-w-7xl  mx-auto px-3 sm:px-0 "
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 1, transition: { duration: 1 } }}
        >
            <MainHead title={title} />
            <NavHeader />
            <main className="min-h-screen space-y-4 mt-4 ">
                {children}
                {sortModal &&
                    sortModal?.showSortModal &&
                    !isNull(sortModal?.element) && <SortModal />}

                <Tooltip>
                    <TooltipTrigger
                        className="print:hidden bg-theme-700 rounded-full fixed bottom-20
                    rtl:left-10 ltr:right-10  md:rtl:left-[5%] md:ltr:right-[5%] xl:ltr:right-[10%]    xl:rtl:left-[10%]    2xl:ltr:right-[15%]    2xl:rtl:left-[15%]        p-4 drop-shadow-xl border border-gray-100 opacity-80 z-40 hover:bg-theme-800 hover:opacity-90"
                    >
                        <a
                            target="_blank"
                            href={`${whatsappUrl(setting.whatsapp)}`}
                        >
                            <FaWhatsapp color="white" size={'30'} />
                        </a>
                    </TooltipTrigger>
                    <TooltipContent
                        className={
                            'max-w-md leading-loose  my-3 rounded-xl bg-theme-100 text-prim-900'
                        }
                    >
                        <p>
                            {t(
                                'for_any_inquiries_plz_contact_our_customer_services',
                            )}
                        </p>
                    </TooltipContent>
                </Tooltip>
            </main>
            <MainFooter />
        </div>
    );
}
